import React from 'react';
import styled from 'styled-components';
import {Button, Result, Space} from 'antd';
import * as AppActions from '../../AppActions';
import qs from 'query-string';

const ERROR = {
  TIER_NOT_FOUND: {
    key: 'tier_not_found',
    title: '店家等級異常',
    description: '店家等級不符合規範，請聯絡工程團隊',
  },
  CHECKOUT_NOT_ALLOW: {
    key: 'checkout_not_allow',
    title: '結帳功能未開通',
    description: '此店家的結帳功能尚未開啟，請聯絡店家所有者',
  },
  UNKNOWN: {
    key: 'unknow',
    title: '發生不預期的錯誤',
    description: '若此錯誤持續發生，請聯絡店家所有者或者工程團隊',
  },
};

function ErrorPage(props) {
  const params = qs.parse(props.location.search);
  const {error} = params;
  const [errorData, setErrorData] = React.useState(null);

  React.useEffect(() => {
    if (error) {
      let foundErrorKey = Object.keys(ERROR).find(
        (e) => ERROR[e].key === error,
      );
      setErrorData(foundErrorKey ? ERROR[foundErrorKey] : ERROR.UNKNOWN);
    } else {
      setErrorData(ERROR.UNKNOWN);
    }
  }, [params]);

  if (!errorData) {
    return (
      <Wrapper>
        <div className="content">
          <Result
            icon={
              <img
                style={{width: 150}}
                src="/images/bakey.png"
                alt="error-image"
              />
            }
            title={'Loading...'}
            subTitle={''}
            extra={
              <Space direction="vertical">
                <Button
                  type="primary"
                  onClick={() => {
                    AppActions.navigate('/store/products');
                  }}>
                  查看商品
                </Button>
              </Space>
            }
          />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="content">
        <Result
          icon={
            <img
              style={{width: 150}}
              src="/images/bakey.png"
              alt="error-image"
            />
          }
          title={errorData.title}
          subTitle={errorData.description}
          extra={
            <Space direction="vertical">
              <Button
                type="primary"
                onClick={() => {
                  AppActions.navigate('/store/products');
                }}>
                查看商品
              </Button>
            </Space>
          }
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  min-height: calc(100vh - var(--topNavBarHeight));

  & > .content {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: var(--basePadding);
    display: 'flex',
    align-items: center;
    justify-content: center;
  }
`;

export default ErrorPage;
